import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faChartBar } from '@fortawesome/free-solid-svg-icons';
import "./RepositoryPage.css";
import { useNavigate } from 'react-router-dom';
import { Card } from '@nextui-org/react';


const RepositoryPage = () => {
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState('');
    const navigate = useNavigate();

    // 分页状态
    const [currentPage, setCurrentPage] = useState(1);
    const surveysPerPage = 6;

    useEffect(() => {
        const fetchSurveys = async () => {
            try {
                const response = await fetch('/api/survey', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const result = await response.json();

                if (result.status_code === 1 && Array.isArray(result.data)) {
                    const activeSurveys = result.data.filter(survey => !survey.is_deleted)
                        .sort((a, b) => b.survey_id - a.survey_id);  // 按ID降序排列;
                    setSurveys(activeSurveys);
                } else {
                    setError('Failed to load surveys.');
                }
            } catch (err) {
                setError('Network error. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchSurveys();
    }, []);

    const deleteSurvey = async (surveyId) => {
        try {
            const response = await fetch(`/api/survey/${surveyId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ is_deleted: true }),
            });
            const result = await response.json();

            if (result.status_code === 1) {
                setSurveys(surveys.filter((survey) => survey.survey_id !== surveyId));
                setNotification('Survey deleted successfully.');
                // 如果删除后当前页数据为空，切换到上一页
                if ((currentPage - 1) * surveysPerPage >= surveys.length - 1) {
                    setCurrentPage(currentPage - 1);
                }
            } else {
                alert('Failed to delete survey.');
            }
        } catch (err) {
            alert('Failed to delete survey. Please try again later.');
        } finally {
            setTimeout(() => setNotification(''), 2000);
        }
    };

    // 计算当前页的显示数据
    const indexOfLastSurvey = currentPage * surveysPerPage;
    const indexOfFirstSurvey = indexOfLastSurvey - surveysPerPage;
    const currentSurveys = surveys.slice(indexOfFirstSurvey, indexOfLastSurvey);

    // 分页控制函数
    const handleNextPage = () => {
        if (currentPage < Math.ceil(surveys.length / surveysPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleCardClick = (survey) => {
        console.log(survey.status)
        if (survey.status === 'draft') {
            navigate(`/editsurvey/${survey.survey_id}`);
        } else if (survey.status === 'published') {
            navigate(`/sharesurvey/${survey.survey_id}`);
        }
    };

    if (loading) return <p>Loading surveys...</p>;
    if (error) return <p>{error}</p>;
    if (surveys.length === 0) return <p>No surveys available.</p>;

    return (
        <div className="repository-page">
            <h1 style={{ color: 'white', fontSize: '58px', margin: '20px 0'}}>Your Surveys</h1>
            <button className="home-button" onClick={() => navigate('/')}>
                Go to Home
            </button>
            {notification && <p className="notification">{notification}</p>}
            {currentSurveys.length > 0 ? (
                <ul>
                    {currentSurveys.map((survey) => (
                        <li key={survey.survey_id} className="survey-item">
                            <div className="survey-content">
                                <Link to={`/sharesurvey/${survey.survey_id}`} className="survey-link">
                                    <h3>{survey.title}</h3>
                                    <p>{survey.description}</p>
                                    {/*<p>Status: {survey.status === 'draft' ? 'Draft' : 'Published'}</p>*/}
                                </Link>
                                {/*<div className="survey-link"  onClick={() => handleCardClick(survey)}>*/}
                                {/*    <h3>*/}
                                {/*        {survey.title}*/}
                                {/*    </h3>*/}
                                {/*    <p>{survey.description}</p>*/}
                                {/*    <p>Status: {survey.status === 'draft' ? 'Draft' : 'Published'}</p>*/}
                                {/*</div>*/}

                            </div>
                            <div className="survey-actions">
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="delete-icon"
                                    onClick={() => deleteSurvey(survey.survey_id)}
                                />
                                <FontAwesomeIcon
                                    icon={faChartBar}
                                    className="analysis-button"
                                    onClick={() => navigate(`/analysis/${survey.survey_id}`)}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No surveys available.</p>
            )}
            {/* 分页按钮 */}
            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    &lt;
                </button>
                <span style={{ color: 'white', fontSize: '16px', margin: '10px 0' }}>Page {currentPage} of {Math.ceil(surveys.length / surveysPerPage)}</span>
                <button onClick={handleNextPage} disabled={currentPage === Math.ceil(surveys.length / surveysPerPage)}>
                    &gt;
                </button>
            </div>
        </div>
    );
};

export default RepositoryPage;
