import React, { useState, useEffect } from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { Button } from '@nextui-org/react';

const HomePage = () => {
    const [inputText, setInputText] = useState(''); // State to store input value
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // Set loading to false initially
    const [showModal, setShowModal] = useState(false);
    const [surveyList, setSurveyList] = useState([]); // State to store survey data

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userAvatar, setUserAvatar] = useState(null); // 用户头像

    // 检查用户是否登录
    const checkLoginStatus = async () => {
        try {
            const sessionResponse = await fetch('/api/session', {
                method: 'GET',
                credentials: 'include',
            });

            if (sessionResponse.ok) {
                const sessionData = await sessionResponse.json();
                if (sessionData.user_id) {
                    setIsLoggedIn(true);
                    setUserAvatar(sessionData.avatar); // 假设用户信息中包含头像
                } else {
                    setIsLoggedIn(false);
                }
            } else {
                setIsLoggedIn(false);
            }
        } catch (error) {
            console.error('Error checking login status:', error);
            setIsLoggedIn(false);
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, []);

    // Repository 按钮点击逻辑
    const handleRepositoryClick = async () => {
        if (!isLoggedIn) {
            navigate('/login');
        } else {
            navigate('/repository'); // 已登录用户跳转到 repository 页面
        }
    };

    // Login 按钮点击逻辑
    const handleLoginClick = async () => {
        if (!isLoggedIn) {
            navigate('/login');
        }
    };

    useEffect(() => {
        // Fetch survey data from the backend on component mount
        const fetchSurveyData = async () => {
            try {
                const response = await fetch('/api/popular_survey', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    setSurveyList(data.data);
                } else {
                    setError('Failed to fetch survey data.');
                }
            } catch (error) {
                console.error('Error fetching survey data:', error);
                setError('Error fetching survey data.');
            }
        };

        fetchSurveyData();
    }, []);

    const handleGenerateClick = async () => {
        try {
            const sessionResponse = await fetch('/api/session', {
                method: 'GET',
                credentials: 'include',
            });

            if (!sessionResponse.ok) {
                throw new Error('Failed to fetch session data');
            }

            const sessionData = await sessionResponse.json();

            if (!sessionData.user_id) {
                navigate('/login');
                return;
            }

            if (!inputText.trim()) {
                setError('Please provide a valid description for the survey.');
                return;
            }

            setLoading(true);

            const response = await fetch('/api/survey/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ description: inputText }),
            });

            const data = await response.json();

            if (response.ok && data.status_code === 1) {
                navigate('/survey', { state: { surveyData: data.data } });
            } else if (data.status_code === 102) {
                navigate('/login');
            } else {
                setError('Failed to generate survey. Please try again.');
            }
        } catch (error) {
            console.error('Error checking login status:', error);
            navigate('/login');
        } finally {
            setLoading(false);
        }
    };

    // Handle card click to populate input field with the survey data
    const handleCardClick = (title, description) => {
        setInputText(`${title}: ${description}`);
        setError(''); // Clear error when a card is clicked
    };

    const defaultAvatar = "https://th.bing.com/th/id/OIP.2UIApIxxhTBiZk62-HBv8QHaHK?w=224&h=216&c=7&r=0&o=5&dpr=1.5&pid=1.7";

    return (
        <div className="homepage">

            <header className="header">
                <img src={require('../assets/logo.jpg')} alt="Logo" className="logo" />
                {/*<button className="login-button" onClick={() => navigate('/login')}>Log in</button>*/}
                {/*<button className="repository-button" onClick={handleRepositoryClick}>Repository</button>*/}
                {isLoggedIn ? (
                    <img
                        src={userAvatar || defaultAvatar}
                        alt="User Avatar"
                        onClick={() => navigate('/repository')}
                        className="avatar"
                    />
                ) : (
                    <button onClick={handleLoginClick} className="login-button">
                        Login
                    </button>
                )}
            </header>

            <div className="main-content">
                <h1 className="title">Survey Questionnaire Platform</h1>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Please enter requirements or click on the reference example below"
                        className="search-input"
                        value={inputText}
                        onChange={(e) => {
                            setInputText(e.target.value);
                            setError('');
                            if (e.target.value.length > 100) {
                                setShowModal(true);
                            }
                        }}
                    />
                    {showModal && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <textarea
                                    className="modal-textarea"
                                    value={inputText}
                                    onChange={(e) => setInputText(e.target.value)}
                                />
                                <button onClick={() => setShowModal(false)}>Finish</button>
                            </div>
                        </div>
                    )}

                    <button
                        className="generate-button"
                        onClick={handleGenerateClick}
                        disabled={loading}
                    >
                        {loading ? (
                            <i className="fas fa-spinner fa-spin"></i>
                        ) : (
                            'Generate'
                        )}
                    </button>
                </div>

                {error && <p className="error-message">{error}</p>}

                <div className="sample-section">
                    <h2>Sample</h2>
                    <div className="card-container">
                        {surveyList.length > 0 ? (
                            surveyList.map((survey, index) => (
                                <div
                                    key={survey.surver_id}
                                    className="card"
                                    onClick={() => handleCardClick(survey.title, survey.description)}
                                >
                                    <h3>{survey.title}</h3>
                                    <p>{survey.description}</p>
                                </div>
                            ))
                        ) : (
                            <p>No sample surveys available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
