import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './AnalysisPage.css';

const AnalysisPage = () => {
    const { survey_id } = useParams(); // 使用 survey_id 而不是 surveyId
    console.log(survey_id); // 确保获取到的值

    const [analysisData, setAnalysisData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAnalysis = async () => {
            try {
                const response = await fetch(`/api/survey_response_analysis/${survey_id}`);
                console.log(typeof survey_id);
                const result = await response.json();
                console.log(result);
                if (result.status_code === 1) {
                    setAnalysisData(result.data);
                } else {
                    setError('Not enough data.');
                }
            } catch (error) {
                setError('API call failed: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        if (survey_id) {
            fetchAnalysis();
        }
    }, [survey_id]);

    if (loading) return <p>Loading analysis...</p>;
    if (error) return <p>{error}</p>;
    if (!analysisData) return <p>No analysis data available.</p>;

    return (
        <div className="analysis-page">
        <div className="analysis-container">
            <h1 className="analysis-header">Survey Analysis</h1>
            <pre className="analysis-content">{JSON.stringify(analysisData, null, 2)}</pre>
        </div>
            <button className="home-button" onClick={() => navigate('/')}>
                Go to Home
            </button>
            <button className="repository-button" onClick={() => navigate('/repository')}>
                Repository
            </button>
        </div>
    );
};

export default AnalysisPage;
