import React, { useState, useEffect } from 'react';
import { useParams , useNavigate } from 'react-router-dom';
import './ShareSurvey.css';
import axios from "axios";
import { QRCodeCanvas } from 'qrcode.react';
import {Image} from "@nextui-org/image";


const ShareSurveyPage = () => {
    const surveyId = useParams(); // 获取 URL 中的 survey_id
    console.log('Survey ID from response:', surveyId.survey_id);
    console.log(typeof surveyId.survey_id);
    const [surveyData, setSurveyData] = useState(null); // 存储获取到的问卷数据
    const [responses, setResponses] = useState({});  // 存储用户的作答
    const [shareLink, setShareLink] = useState("");
    const navigate = useNavigate();

    const [imageUrls, setImageUrls] = useState({});

    // const [imageUrl, setImageUrl] = useState(null);

    // useEffect(() => {
    //     console.log(typeof surveyId.survey_id);
    //     if (surveyId) {
    //         // 通过 GET 请求获取问卷内容
    //         axios.get(`/api/survey/${surveyId.survey_id}`)//${surveyIdInt}
    //             .then(response => {
    //                 console.log('Survey ID from response:', response.data.data.survey_id);
    //                 console.log('API response:', response.data); // 打印整个响应对象
    //
    //                 // Set the shareable link based on the surveyId
    //                 const link = `${window.location.origin}/sharesurvey/${surveyId.survey_id}`;
    //                 setShareLink(link);
    //
    //                 // 检查状态码
    //                 if (response.data.status_code === 1) {
    //                     const surveyData = response.data.data; // 获取 survey 数据
    //
    //                     // 提取所需的信息
    //                     const surveyTitle = surveyData.title;
    //                     const surveyDescription = surveyData.description;
    //                     const questions = surveyData.json_data.questions;
    //
    //                     //fetch image
    //                     const imageId = surveyData.json_data.questions.find(q => q.imageId)?.imageId;
    //                     console.log(imageId);
    //                     if (imageId) {
    //                         axios.get(`/api/asset?asset_id=${imageId}`, {responseType: 'blob'}) // Fetch as blob
    //                             .then(res => {
    //                                 // console.log(res.data);
    //                                 // const imageUrl = URL.createObjectURL(res.data); // Create URL for blob
    //                                 // console.log(imageUrl);
    //                                 // setImageUrl(imageUrl); // Set the image URL
    //                                 if (res.headers['content-type'].startsWith('image/')) {
    //                                     const imageUrl = URL.createObjectURL(res.data); // Create a URL for the image blob
    //                                     setImageUrl(imageUrl); // Set the URL to display the image
    //                                 } else {
    //                                     res.data.text().then(text => {
    //                                         console.log("Error response:", text); // Log any non-image response
    //                                         alert("Failed to load image.");
    //                                     });
    //                                 }
    //                             })
    //                             .catch(error => {
    //                                 console.error("Error fetching image:", error);
    //                             });
    //                     }
    //                     // 更新状态以存储调查数据
    //                     setSurveyData({
    //                         title: surveyTitle,
    //                         description: surveyDescription,
    //                         questions: questions
    //                     });
    //
    //
    //                 } else {
    //                     console.error("Request failed:", response.data.message); // 处理请求失败的情况
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error("Error fetching survey data:", error); // 处理请求错误
    //             });
    //     }
    // }, [surveyId]);

    useEffect(() => {
        if (surveyId) {
            axios.get(`/api/survey/${surveyId.survey_id}`)
                .then(response => {
                    const surveyData = response.data.data;
                    const questions = surveyData.json_data.questions;
                    const link = `${window.location.origin}/sharesurvey/${surveyId.survey_id}`;
                    setShareLink(link);

                    setSurveyData({
                        title: surveyData.title,
                        description: surveyData.description,
                        questions: questions
                    });

                    // Fetch images for each question if imageId exists
                    const fetchImages = async () => {
                        const urls = {};
                        for (const question of questions) {
                            if (question.imageId) {
                                try {
                                    const res = await axios.get(`/api/asset?asset_id=${question.imageId}`, { responseType: 'blob' });
                                    if (res.headers['content-type'].startsWith('image/')) {
                                        const imageUrl = URL.createObjectURL(res.data);
                                        urls[question.question_id] = imageUrl;
                                    }
                                } catch (error) {
                                    console.error(`Error fetching image for question ${question.question_id}:`, error);
                                }
                            }
                        }
                        setImageUrls(urls);
                    };

                    fetchImages();
                })
                .catch(error => {
                    console.error("Error fetching survey data:", error);
                });
        }
    }, [surveyId]);

    // Function to copy the share link to the clipboard
    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareLink).then(() => {
            alert('Link copied to clipboard!');
        }, () => {
            alert('Failed to copy the link.');
        });
    };

    const handleInputChange = (questionId, value) => {
        setResponses({
            ...responses,
            [questionId]: value
        });
    };

    const handleSubmit = () => {
        // 提交用户作答
        const surveyResponses = {
            status_code: 1, // 设定状态码
            message: "success", // 设定消息
            data: {
                response_id: surveyId,
                json_data: responses,
                created_at: Date.now() / 1000
            }
            // surveyId,
            // responses
        };

        axios.post(`/api/survey_response/${surveyId.survey_id}`, surveyResponses)
            .then(response => {
                alert('Survey Submitted!');
            })
            .catch(error => {
                console.error("Error submitting survey responses:", error);
            });
        console.log('Submitted responses:', surveyResponses);
        console.log('Submitted responses:', responses);
        navigate("/");
    };

    if (!surveyData) {
        return <div>Loading survey data...</div>;
    }

    return (
        <div className="share-survey-page">
            <div className="share-container">
                <button className="home-button" onClick={() => navigate('/')}>
                    Go to Home
                </button>
                <button className="repository-button" onClick={() => navigate('/repository')}>
                    Repository
                </button>
                <h1>{surveyData.title}</h1>
                <p>{surveyData.description}</p>

                <form className="survey-form">
                    {surveyData.questions.map((question) => (
                        <div key={question.question_id} className="survey-question">
                            <label className="question-title">
                                {question.question_title}
                                {question.required && <span className="required"> *</span>}
                            </label>
                            <p className="question-description">{question.question_description}</p>

                            {/* Display image if it exists */}
                            {imageUrls[question.question_id] && (
                                <Image width={240}
                                        src={imageUrls[question.question_id]} alt={`Question ${question.question_id}`} className="question-image" />
                            )}

                            {/* 根据问题类型渲染不同的输入组件 */}
                            {question.question_type === 'single_choice' && (
                                <div className="single-choice-options">
                                    {question.options.map((option) => (
                                        <div key={option.option_id} className="option-row">
                                            <label className="option-label">
                                                <input
                                                    type="radio"
                                                    name={question.question_id}
                                                    value={option.option_text}
                                                    onChange={() => handleInputChange(question.question_id, option.option_text)}
                                                    required={question.required}
                                                />
                                                {option.option_text}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {question.question_type === 'multiple_choice' && (
                                <div className="multiple-choice-options">
                                    {question.options.map((option) => (
                                        <div key={option.option_id} className="option-row">
                                            <label className="option-label">
                                                <input
                                                    type="checkbox"
                                                    value={option.option_text}
                                                    onChange={(e) => {
                                                        const value = e.target.checked
                                                            ? [...(responses[question.question_id] || []), option.option_text]
                                                            : (responses[question.question_id] || []).filter(
                                                                (item) => item !== option.option_text
                                                            );
                                                        handleInputChange(question.question_id, value);
                                                    }}
                                                />
                                                {option.option_text}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {question.question_type === 'open_ended' && (
                                <textarea
                                    placeholder="Your response"
                                    value={responses[question.question_id] || ''}
                                    onChange={(e) => handleInputChange(question.question_id, e.target.value)}
                                    required={question.required}
                                />
                            )}

                            {question.question_type === 'rating' && (
                                <div className="rating-scale">
                                    {[...Array(question.max_value - question.min_value + 1)].map((_, index) => {
                                        const value = question.min_value + index;
                                        return (
                                            <label key={value}>
                                                <input
                                                    type="radio"
                                                    name={question.question_id}
                                                    value={value}
                                                    onChange={() => handleInputChange(question.question_id, value)}
                                                    required={question.required}
                                                />
                                                {value}
                                            </label>
                                        );
                                    })}
                                    <div className="rating-labels">
                                        <span>{question.min_label}</span>
                                        <span>{question.max_label}</span>
                                    </div>
                                </div>
                            )}

                            {question.question_type === 'numeric' && (
                                <input
                                    type="number"
                                    min={question.min_value}
                                    max={question.max_value}
                                    value={responses[question.question_id] || ''}
                                    onChange={(e) => handleInputChange(question.question_id, e.target.value)}
                                    required={question.required}
                                />
                            )}

                            {question.question_type === 'date' && (
                                <input
                                    type="date"
                                    min={new Date().toISOString().split('T')[0]}
                                    max={question.max_date}
                                    value={responses[question.question_id] || ''}
                                    onChange={(e) => handleInputChange(question.question_id, e.target.value)}
                                    required={question.required}
                                />
                            )}


                        </div>
                    ))}

                    <button type="button" onClick={handleSubmit} className="submit-button">
                        Submit Survey
                    </button>

                    <div className="share-link-container">
                        <h3>Share this survey:</h3>
                        <input type="text" value={shareLink} readOnly className="share-link-input"/>
                        <button onClick={handleCopyLink} className="copy-link-button">Copy Link</button>
                        {/* 添加二维码显示区域 */}
                        <div className="qr-code-container">
                            <p>Scan QR</p>
                            <QRCodeCanvas value={shareLink} size={128} />
                        </div>

                    </div>

                </form>
            </div>
        </div>
    );
};

export default ShareSurveyPage;