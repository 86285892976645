import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './AdminPage.css';

function AdminPage({ setIsLoggedIn }) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('api/session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });

            if (response.status === 401) {
                // Unauthorized (email or password incorrect)
                alert('Incorrect email or password, please try again!');
                return;
            }

            if (!response.ok) {
                throw new Error('Network request failed');
            }

            const data = await response.json();

            // Check if the status code indicates a successful request
            if (data.status_code === 1) {
                // Check if the user is an admin
                if (data.data.is_admin) {
                    setIsLoggedIn(true);
                    navigate('/adminmanagement'); // Adjust the navigation page as necessary
                } else {
                    alert('Access denied. This account is not an administrator.');
                }
            } else {
                alert('Login failed, please check your email and password are correct!');
            }
        } catch (error) {
            console.error('Error during login:', error);
            alert('An error occurred during login, please try again later!');
        }
    };

    return (
        <div className="adminlogin-page">
            <div className="login-form-container">
                <form className="login-form" onSubmit={handleLogin}>
                    <img src={require('./../assets/logo.jpg')} alt="UTS Logo" className="logo" />

                    <h1>Admin Login</h1>

                    <div className="input-container">
                        <input
                            type="email"
                            placeholder="Email Address"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="input-container">
                        <input
                            type="password"
                            placeholder="Password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <a href="#">Forgot password?</a>

                    <button type="submit">Log in</button>
                    <p>New user? <Link to="/adminregister">Register</Link></p>
                </form>
            </div>
        </div>
    );
}

export default AdminPage;
