import React, { useState } from 'react';
import './LoginPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons'; // 管理员图标
import { useNavigate } from 'react-router-dom'; // 使用 react-router 进行路由跳转

const LoginPage = ({ setIsLoggedIn }) => {
    const navigate = useNavigate(); // 获取 navigate 函数
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(''); // 用于显示错误提示

    // 跳转到管理员页面
    const handleAdminClick = () => {
        navigate('/admin'); // 设置管理员页面的正确路由路径
    };

    // 表单提交处理函数
    const handleSubmit = async (e) => {
        e.preventDefault();

        // 前端输入验证
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }
        if (!validatePassword(password)) {
            setError('Your password must be at least 6 characters long.');
            return;
        }

        setError(''); // 清空错误消息

        // 向后端发送登录请求
        try {
            const response = await fetch('/api/session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            //check status_code in response
            if (data.status_code === 1){
                // localStorage.setItem('email', email); // 保存email到localStorage
                navigate('/');
            } else {
                switch (data.status_code) {
                    case 0:
                        setError('Login failed. Please check your email and password.');
                        break;
                    case -1:
                        setError('System error. Please try again later.');
                        break;
                    case 101:
                        setError('User not authenticated. Please log in again.');
                        break;
                    case 102:
                        setError('Unauthorized access. Please check your permissions.');
                        break;
                    case 1021:
                        setError('Invalid input. Please verify your credentials.');
                        break;
                    default:
                        setError('An unexpected error occurred.');
                    }
                }
            }catch (error) {
            setError('Network error. Please try again later.');
            }
    };

    // 验证邮箱格式
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // 验证密码格式
    const validatePassword = (password) => {
        return password.length >= 6;
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <img src={require('../assets/logo.jpg')} alt="Logo" className="logo" />
                <h1>Survey Questionnaire Platform</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <a href="#forgot-password" className="forgot-password">
                        Forgot password?
                    </a>
                    <button type="submit">Log in</button>
                    {/* 错误提示信息 */}
                    {error && <p className="error-message">{error}</p>}
                </form>
                {/* 管理员图标，点击跳转 */}
                <div
                    className="admin-icon"
                    onClick={handleAdminClick}
                    role="button"
                    aria-label="Go to Admin"
                >
                    <FontAwesomeIcon icon={faUserShield} size="2x" />
                </div>
                <div className="register">
                    <p>New user?</p>
                    <button onClick={() => navigate('/register')}>Register</button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
