import React, { useState, useEffect } from 'react';
import { Button, Chip, Tooltip } from '@nextui-org/react';
import { EditIcon } from './EditIcon';
import './AdminManagement.css';

const statusColorMap = {
    Yes: "success",
    No: "default",
};

function AdminManagement() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingUser, setEditingUser] = useState(null);
    const [editedUser, setEditedUser] = useState({});

    useEffect(() => {
        async function fetchUsers() {
            try {
                const response = await fetch('api/user', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();
                if (response.ok && data.status_code === 1 && data.data.length > 0) {
                    setUsers(data.data);
                } else {
                    console.error('Failed to fetch users or no users found.');
                }
            } catch (error) {
                console.error('Failed to fetch:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchUsers();
    }, []);

    const handleEditClick = (user) => {
        setEditingUser(user.user_id);
        setEditedUser(user);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEditedUser({
            ...editedUser,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSaveClick = async () => {
        try {
            const response = await fetch(`api/user/${editedUser.user_id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedUser),
            });

            if (response.ok) {
                const updatedUser = await response.json();
                setUsers(users.map(user => user.user_id === updatedUser.user_id ? updatedUser : user));
                setEditingUser(null);
            } else {
                console.error('Failed to update user:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const handleCancelClick = () => {
        setEditingUser(null);
    };

    const handleManageSurveys = () => {
        window.location.href = '/survey-management';
    };

    return (
        <div className="admin-management">
            <h1>User Management Interface</h1>
            <Button className="survey-button" onPress={handleManageSurveys}>
                Survey Management
            </Button>
            {/*<div className="user-loading">*/}
                {loading ? (
                    <div className="user-loading-spinner">
                        <dive className={"user-loading"}></dive>
                        <p>Loading...</p>
                    </div>
                ) : (
                    users.length > 0 ? (
                        <table className="user-table">
                            <thead>
                                <tr>
                                    <th>User ID</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Is Admin</th>
                                    <th>Is Banned</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr key={user.user_id}>
                                        <td>{user.user_id}</td>
                                        <td>
                                            {editingUser === user.user_id ? (
                                                <input
                                                    type="text"
                                                    name="username"
                                                    value={editedUser.username}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                user.username
                                            )}
                                        </td>
                                        <td>
                                            {editingUser === user.user_id ? (
                                                <input
                                                    type="text"
                                                    name="email"
                                                    value={editedUser.email}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                user.email
                                            )}
                                        </td>
                                        <td>
                                            {editingUser === user.user_id ? (
                                                <input
                                                    type="checkbox"
                                                    name="is_admin"
                                                    checked={editedUser.is_admin}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                <Chip color={statusColorMap[user.is_admin ? 'Yes' : 'No']} size="sm" variant="flat">
                                                    {user.is_admin ? 'Yes' : 'No'}
                                                </Chip>
                                            )}
                                        </td>
                                        <td>
                                            {editingUser === user.user_id ? (
                                                <input
                                                    type="checkbox"
                                                    name="is_banned"
                                                    checked={editedUser.is_banned}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                <Chip color={statusColorMap[user.is_banned ? 'Yes' : 'No']} size="sm" variant="flat">
                                                    {user.is_banned ? 'Yes' : 'No'}
                                                </Chip>
                                            )}
                                        </td>
                                        <td>
                                            {editingUser === user.user_id ? (
                                                <>
                                                    <Button className="save-button" onPress={handleSaveClick}>Save</Button>
                                                    <Button className="cancel-button" onPress={handleCancelClick}>Cancel</Button>
                                                </>
                                            ) : (
                                                <Tooltip content="Edit user">
                                                    <span className="button-icon" onClick={() => handleEditClick(user)}>
                                                        <EditIcon />
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No users found.</p>
                    )
                )}
            {/*</div>*/}
        </div>
    );
}

export default AdminManagement;
