import './App.css';
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import AdminPage from './components/AdminPage';
import HomePage from './components/HomePage';
import RegisterPage from "./components/RegisterPage";
import AdminManagement from './components/AdminManagement'; //
import SurveyPage from "./components/SurveyPage";
import ShareSurvey from "./components/ShareSurvey";
import SurveyManagement from './components/SurveyManagement'; // 新增 SurveyManagement 导入
import RepositoryPage from "./components/RepositoryPage"
import AnalysisPage from  "./components/AnalysisPage"

const App = () => {
  // State to manage login status
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
      <Routes>
        {/* Define the routes for your app */}
        <Route
            path="/"
            element={<HomePage isLoggedIn={isLoggedIn} />} // Pass login status to HomePage
        />
        <Route
            path="/login"
            element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} // Pass setLogin function to LoginPage
        />
        <Route path="/admin" element={<AdminPage setIsLoggedIn={setIsLoggedIn}/>} /> {/* URL path for admin */}
        <Route path="/adminmanagement" element={<AdminManagement />} />  //
        <Route path="/register" element={<RegisterPage />} /> {/* URL path for RegisterPage */}
          <Route
              path="/survey"
              element={<SurveyPage />}
          />
          <Route path="/sharesurvey/:survey_id" element={<ShareSurvey />}/>
          {/*<Route path="/sharesurvey/${survey_id}" element={<ShareSurvey />}/>*/}
          <Route path="/repository" element={<RepositoryPage />} />
          <Route path="/analysis/:survey_id" element={<AnalysisPage />} />
        <Route path="/survey-management" element={<SurveyManagement />} /> {/* 新增的 SurveyManagement 路由 */}
      </Routes>
  );
};

export default App;
