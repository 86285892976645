import React, { useEffect, useState } from 'react';
import { Tooltip } from '@nextui-org/react';
import { EditIcon } from './EditIcon';
import { EyeIcon } from './EyeIcon';
import './SurveyManagement.css';

const SurveyManagement = () => {
    const [surveys, setSurveys] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const [editingSurveyId, setEditingSurveyId] = useState(null);
    const [editingContent, setEditingContent] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        status: '',
        is_deleted: false,
        close_date: '',
    });
    const [editableQuestions, setEditableQuestions] = useState([]);
    const [analysisData, setAnalysisData] = useState(null); // State to hold AI analysis data

    useEffect(() => {
        setIsLoading(true);
        fetch('/api/survey')
            .then(response => response.json())
            .then(data => {
                if (data.status_code === 1) {
                    setSurveys(data.data);
                } else {
                    console.error('Failed to fetch surveys:', data.message);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching surveys:', error);
                setIsLoading(false);
            });
    }, []);

    const handleViewClick = (survey) => {
        setSelectedSurvey(survey);
        setEditingSurveyId(null);
        setEditingContent(false);
        setEditableQuestions(survey.json_data?.questions || []);
    };

    const handleEditClick = (survey) => {
        setEditingSurveyId(survey.survey_id);
        setFormData({
            title: survey.title,
            description: survey.description,
            status: survey.status,
            is_deleted: survey.is_deleted,
            close_date: new Date(survey.closed_at * 1000).toISOString().slice(0, 16),
        });
    };

    const handleAnalysisClick = (surveyId) => {
        // Fetch AI analysis data from the backend API
        fetch(`/api/survey_response_analysis/${surveyId}`)
            .then(response => response.json())
            .then(data => {
                if (data.status_code === 1) {
                    setAnalysisData(data.data); // Set the analysis data to state
                } else {
                    console.error('Failed to fetch analysis:', data.message);
                }
            })
            .catch(error => console.error('Error fetching analysis:', error));
    };

    const handleModalEditClick = () => {
        setEditingContent(true);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleQuestionChange = (index, field, value) => {
        const updatedQuestions = [...editableQuestions];
        updatedQuestions[index][field] = value;
        setEditableQuestions(updatedQuestions);
    };

    const handleOptionChange = (questionIndex, optionIndex, value) => {
        const updatedQuestions = [...editableQuestions];
        updatedQuestions[questionIndex].options[optionIndex].option_text = value;
        setEditableQuestions(updatedQuestions);
    };

    const handleSaveClick = (surveyId) => {
        const closeDateTimestamp = Math.floor(new Date(formData.close_date).getTime() / 1000);

        const updatedData = {
            ...formData,
            close_date: closeDateTimestamp,
        };

        fetch(`/api/survey/${surveyId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status_code === 1) {
                    setSurveys(surveys.map(s => (s.survey_id === surveyId ? data.data : s)));
                    setEditingSurveyId(null);
                    alert('Survey updated successfully.');
                } else {
                    console.error('Failed to update survey:', data.message);
                }
            })
            .catch(error => console.error('Error updating survey:', error));
    };

    const handleSaveContentClick = () => {
        const updatedData = {
            ...selectedSurvey,
            json_data: {
                ...selectedSurvey.json_data,
                questions: editableQuestions
            }
        };

        fetch(`/api/survey/${selectedSurvey.survey_id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.status_code === 1) {
                const updatedSurveys = surveys.map(s => (s.survey_id === selectedSurvey.survey_id ? data.data : s));
                setSurveys(updatedSurveys);
                setSelectedSurvey(data.data);
                setEditingContent(false);
                alert('Survey content updated successfully.');
            } else {
                console.error('Failed to update survey content:', data.message);
            }
        })
        .catch(error => console.error('Error updating survey content:', error));
    };

    const handleCancelClick = () => {
        setEditingSurveyId(null);
    };

    const closeModal = () => {
        setSelectedSurvey(null);
    };

    const closeAnalysisModal = () => {
        setAnalysisData(null); // Close the analysis modal by setting analysis data to null
    };

    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const renderSurveyDetails = (survey) => {
        if (!survey || !survey.json_data) {
            return <p>No survey content available.</p>;
        }

        return (
            <div className="survey-details">
                <h2>{survey.json_data.survey_title}</h2>
                <p>{survey.json_data.survey_description}</p>
                <div className="questions">
                    {editableQuestions.map((question, questionIndex) => (
                        <div key={questionIndex} className="question">
                            {editingContent ? (
                                <>
                                    <h3>{`Question ${questionIndex + 1}`}</h3>
                                    <input
                                        type="text"
                                        value={question.question_title}
                                        onChange={(e) => handleQuestionChange(questionIndex, 'question_title', e.target.value)}
                                        placeholder="Question Title"
                                    />
                                    <textarea
                                        value={question.question_description}
                                        onChange={(e) => handleQuestionChange(questionIndex, 'question_description', e.target.value)}
                                        placeholder="Question Description"
                                        style={{ width: '100%', margin: '8px 0' }}
                                    />
                                    {question.question_type === 'single_choice' || question.question_type === 'multiple_choice' ? (
                                        question.options.map((option, optionIndex) => (
                                            <div key={optionIndex} style={{ margin: '4px 0', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginRight: '8px' }}>{`${optionIndex + 1}.`}</span>
                                                <input
                                                    type="text"
                                                    value={option.option_text}
                                                    onChange={(e) => handleOptionChange(questionIndex, optionIndex, e.target.value)}
                                                    placeholder="Option Text"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        ))
                                    ) : question.question_type === 'numeric' ? (
                                        <p>Rate from {question.min_value} to {question.max_value}</p>
                                    ) : (
                                        <p>Open-ended question</p>
                                    )}
                                </>
                            ) : (
                                <>
                                    <h3>{`Question ${questionIndex + 1}: ${question.question_title}`}</h3>
                                    <p>{question.question_description}</p>
                                    {question.question_type === 'single_choice' || question.question_type === 'multiple_choice' ? (
                                        question.options.map((option, optionIndex) => (
                                            <div key={optionIndex} style={{ margin: '4px 0' }}>
                                                <span>{`${optionIndex + 1}. ${option.option_text}`}</span>
                                            </div>
                                        ))
                                    ) : question.question_type === 'numeric' ? (
                                        <p>Rate from {question.min_value} to {question.max_value}</p>
                                    ) : (
                                        <p>Open-ended question</p>
                                    )}
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="survey-management">
            <div className="survey-management-content">
                <h1>Survey Questionnaire Management</h1>

                {isLoading ? (
                    <div className="loading">
                        <div className="loading-spinner"></div>
                        <span>Loading...</span>
                    </div>
                ) : (
                    <>
                        <table className="survey-table">
                            <thead>
                                <tr>
                                    <th>Survey ID</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Is Deleted</th>
                                    <th>Close Date</th>
                                    <th>Questionnaire</th>
                                    <th>Modification</th>
                                    <th>AI Analysis</th> {/* New column for AI Analysis */}
                                </tr>
                            </thead>
                            <tbody>
                                {surveys.map((survey) => (
                                    <tr key={survey.survey_id}>
                                        <td>{survey.survey_id}</td>
                                        <td>
                                            {editingSurveyId === survey.survey_id && survey.status === 'draft' ? (
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={formData.title}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                survey.title
                                            )}
                                        </td>
                                        <td>
                                            {editingSurveyId === survey.survey_id && survey.status === 'draft' ? (
                                                <textarea
                                                    name="description"
                                                    value={formData.description}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                survey.description
                                            )}
                                        </td>
                                        <td>
                                            {editingSurveyId === survey.survey_id ? (
                                                <select
                                                    name="status"
                                                    value={formData.status}
                                                    onChange={handleInputChange}
                                                    disabled={formData.status === 'published'}
                                                >
                                                    <option value="draft">Draft</option>
                                                    <option value="published">Published</option>
                                                </select>
                                            ) : (
                                                survey.status
                                            )}
                                        </td>
                                        <td>
                                            {editingSurveyId === survey.survey_id ? (
                                                <input
                                                    type="checkbox"
                                                    name="is_deleted"
                                                    checked={formData.is_deleted}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                survey.is_deleted ? 'Yes' : 'No'
                                            )}
                                        </td>
                                        <td>
                                            {editingSurveyId === survey.survey_id ? (
                                                <input
                                                    type="datetime-local"
                                                    name="close_date"
                                                    value={formData.close_date}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                formatDateTime(survey.closed_at)
                                            )}
                                        </td>
                                        <td>
                                            <Tooltip content="View survey">
                                                <span className="button-icon" onClick={() => handleViewClick(survey)}>
                                                    <EyeIcon />
                                                </span>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            {editingSurveyId === survey.survey_id ? (
                                                <>
                                                    <button className="save-button" onClick={() => handleSaveClick(survey.survey_id)}>Save</button>
                                                    <button className="cancel-button" onClick={handleCancelClick}>Cancel</button>
                                                </>
                                            ) : (
                                                <Tooltip content="Edit survey">
                                                    <span className="button-icon" onClick={() => handleEditClick(survey)}>
                                                        <EditIcon />
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </td>
                                        <td> {/* New cell for AI Analysis button */}
                                            <button onClick={() => handleAnalysisClick(survey.survey_id)}>Analyze</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Modal for displaying AI analysis data */}
                        {analysisData && (
                            <div className="modal">
                                <div className="modal-content">
                                    <h2>AI Analysis Result</h2>
                                    <pre>{analysisData}</pre>
                                    <button className="close-button" onClick={closeAnalysisModal}>Close</button>
                                </div>
                            </div>
                        )}

                        {selectedSurvey && (
                            <div className="modal">
                                <div className="modal-content">
                                    {editingContent ? (
                                        <>
                                            <button className="save-button" onClick={handleSaveContentClick}>Save</button>
                                            <button className="cancel-button" onClick={() => setEditingContent(false)}>Cancel</button>
                                        </>
                                    ) : (
                                        <button
                                            className="edit-content-button"
                                            onClick={handleModalEditClick}
                                            disabled={!selectedSurvey.json_data || !selectedSurvey.json_data.questions || selectedSurvey.json_data.questions.length === 0}
                                        >
                                            Edit
                                        </button>
                                    )}
                                    <button className="close-button" onClick={closeModal}>Close</button>
                                    {renderSurveyDetails(selectedSurvey)}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default SurveyManagement;
