import React, { useState } from 'react';
import './RegisterPage.css';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        if (password.length < 6) {
            setError('Password must be at least 6 characters long.');
            return;
        }

        setError('');

        if (!verificationSent) {
            try {
                const response = await fetch('/api/verification_code', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, purpose: 'register' }),
                });

                const data = await response.json();

                if (data.status_code === 1) {
                    setVerificationSent(true);
                } else {
                    setError(data.message || 'Failed to send verification code, please try again');
                }
            } catch (error) {
                setError('Network error, please try again later.');
            }
        } else {
            try {
                const response = await fetch('/api/user/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username,
                        email,
                        password,
                        verification_code: verificationCode
                    }),
                });

                const data = await response.json();

                if (data.status_code === 1) {
                    setIsVerified(true);
                    navigate('/login');
                } else {
                    setError(data.message || 'Invalid verification code.');
                }
            } catch (error) {
                setError('Network error, please try again later.');
            }
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div className="register-page">
            <div className="register-container">
                <h1>Register</h1>
                <form onSubmit={handleSubmit}>
                    {!verificationSent && (
                        <>
                            <input
                                type="text"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button type="submit">Send Verification Code</button>
                        </>
                    )}

                    {verificationSent && (
                        <>
                            <input
                                type="text"
                                placeholder="Enter Verification Code"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                required
                            />
                            <button type="submit">Verify and Register</button>
                        </>
                    )}
                    {error && <p className="error-message">{error}</p>}
                </form>
                <div className="login-link">
                    <p>Already have an account?</p>
                    <button onClick={() => navigate('/login')}>Log in</button>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
