import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SurveyPage.css';
import axios from "axios";
import {Image} from "@nextui-org/image";

const SurveyPage = () => {
    const navigate = useNavigate(); // Hook for navigation
    const location = useLocation();
    const { surveyData } = location.state || {};  // Retrieve the passed survey data
    const [responses, setResponses] = useState({});  // Store user responses
    // const [questions, setQuestions] = useState(surveyData.questions || []); // Maintain survey questions
    const [newQuestionType, setNewQuestionType] = useState('');
    const [questions, setQuestions] = useState(surveyData?.questions?.map(question => ({
        ...question,
        image: null // New property for storing image
    })) );//|| []


    const handleInputChange = (questionId, value) => {
        setResponses({
            ...responses,
            [questionId]: value
        });
    };

    const handleAddNewQuestion = (index) => {
        if (newQuestionType) {
            const newQuestion = {
                question_id: Date.now(), // Unique ID for the new question
                question_title: 'Please enter your question',
                question_type: newQuestionType,
                required: false,
                options: [],
                question_description: ''
            };

            // Insert the new question after the current question
            const updatedQuestions = [
                ...questions.slice(0, index + 1),
                newQuestion,
                ...questions.slice(index + 1)
            ];
            setQuestions(updatedQuestions);
            setNewQuestionType(''); // Reset the new question type
        }
    };

    const handleDeleteQuestion = (questionId) => {
        setQuestions(questions.filter(question => question.question_id !== questionId));
    };

    const handleEditQuestion = (questionId, field, value) => {
        setQuestions(questions.map(question =>
            question.question_id === questionId ? { ...question, [field]: value } : question
        ));
    };

    const handleAddOption = (questionId) => {
        setQuestions(questions.map(question => {
            if (question.question_id === questionId) {
                return {
                    ...question,
                    options: [...question.options, { option_id: Date.now(), option_text: 'New Option' }]
                };
            }
            return question;
        }));
    };

    const handleDeleteOption = (questionId, optionId) => {
        setQuestions(questions.map(question => {
            if (question.question_id === questionId) {
                return {
                    ...question,
                    options: question.options.filter(option => option.option_id !== optionId)
                };
            }
            return question;
        }));
    };

    const handleEditOption = (questionId, optionId, value) => {
        setQuestions(questions.map(question => {
            if (question.question_id === questionId) {
                return {
                    ...question,
                    options: question.options.map(option =>
                        option.option_id === optionId ? { ...option, option_text: value } : option
                    )
                };
            }
            return question;
        }));
    };

    // 上传图片到后端，并保存生成的图片ID
    const handleImageUpload = (e, questionId) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            axios.post("/api/asset", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    const { data } = response.data; // Extract the imageId from the response
                    console.log('image ID from response:', data);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setQuestions(questions.map(question => {
                            if (question.question_id === questionId) {
                                return { ...question, imageId: data, image: reader.result}; // Save the imageId to the question
                            }
                            return question;
                        }));
                    };
                    reader.readAsDataURL(file);


                })
                .catch(error => {
                    console.error("Error uploading image", error);
                });
        }
    };

    const handleSubmit = (status) => {
        // Create a new array of questions without the `image` field, only keeping `imageId`
        const questionsWithoutImageData = questions.map(({ image, ...question }) => question);
        const survey_json = {
            survey_title: surveyData.survey_title,
            survey_description: surveyData.survey_description,
            questions: questionsWithoutImageData
        };

        const survey_status = status;
        const close_date = new Date().toISOString().replace('T', ' ').split('.')[0];


        // Make the POST request to save the survey
        axios.post("/api/survey", {
            survey_json,    // The survey content
            survey_status,  // Status of the survey (e.g., draft, published)
            close_date      // The survey closing date
        })
            .then(response => {
                const surveyId = response.data.data.survey_id;
                console.log('Survey ID from response:', response.data.data.survey_id);
                console.log(typeof surveyId);
                // alert('Survey Submitted! Check the console for the data.');
                alert(`Survey ${status.charAt(0).toUpperCase() + status.slice(1)}ed!`);
                if (status === "draft") {
                    navigate("/repository", { state: { surveyId } });
                } else {
                    navigate(`/sharesurvey/${surveyId}`, { state: { surveyId } });
                }
                // navigate(`/sharesurvey/${response.data.data.survey_id}`, { state: { surveyId: response.data.data.survey_id } });
            })
            .catch(error => {
                console.error("Error saving survey", error);
            });
        // Handle form submission
        console.log('Submitted responses:', responses);
    };

    if (!surveyData) {
        return <div>Survey data not found</div>;
    }

    return (
        <div className="survey-page">
        <div className="survey-container">
            <h1>{surveyData.survey_title}</h1>
            <p>{surveyData.survey_description}</p>

            <form className="survey-form">
                {questions.map((question, index) => (
                    <div key={question.question_id} className="survey-question">
                        <label className="question-title">
                            <input
                                type="text"
                                value={question.question_title}
                                onChange={(e) => handleEditQuestion(question.question_id, 'question_title', e.target.value)}
                            />
                            {question.required && <span className="required"> *</span>}
                        </label>
                        <p className="question-description">
                            <input
                                type="text"
                                placeholder="Additional description of the question (optional)"
                                value={question.question_description}
                                onChange={(e) => handleEditQuestion(question.question_id, 'question_description', e.target.value)}
                            />
                        </p>

                        {/* Render different types of questions */}
                        {question.question_type === 'single_choice' && (
                            <div className="single-choice-options">
                                {question.options.map((option) => (
                                    <div key={option.option_id} className="option-row">
                                        <label className="option-label">
                                            <input
                                                type="radio"
                                                name={question.question_id}
                                                value={option.option_text}
                                                onChange={() => handleInputChange(question.question_id, option.option_text)}
                                                required={question.required}
                                            />
                                            <input
                                                type="text"
                                                value={option.option_text}
                                                onChange={(e) => handleEditOption(question.question_id, option.option_id, e.target.value)}
                                            />
                                            <button type="button" onClick={() => handleDeleteOption(question.question_id, option.option_id)}>−</button>
                                        </label>
                                    </div>
                                ))}
                                <button type="button" onClick={() => handleAddOption(question.question_id)}>Add Option</button>
                            </div>
                        )}

                        {question.question_type === 'multiple_choice' && (
                            <div className="multiple-choice-options" >
                                {question.options.map((option) => (
                                    <div key={option.option_id} className="option-row">
                                        <label className="option-label">
                                            <input
                                                type="checkbox"
                                                value={option.option_text}
                                                onChange={(e) => {
                                                    const value = e.target.checked
                                                        ? [...(responses[question.question_id] || []), option.option_text]
                                                        : (responses[question.question_id] || []).filter(
                                                            (item) => item !== option.option_text
                                                        );
                                                    handleInputChange(question.question_id, value);
                                                }}
                                            />
                                            <input
                                                type="text"
                                                value={option.option_text}
                                                onChange={(e) => handleEditOption(question.question_id, option.option_id, e.target.value)}
                                            />
                                            <button type="button" onClick={() => handleDeleteOption(question.question_id, option.option_id)}>−</button>
                                        </label>
                                    </div>
                                ))}
                                <button type="button" onClick={() => handleAddOption(question.question_id)}>Add Option</button>
                            </div>
                        )}

                        {question.question_type === 'open_ended' && (
                            <textarea
                                placeholder="Your response"
                                value={responses[question.question_id] || ''}
                                onChange={(e) => handleInputChange(question.question_id, e.target.value)}
                                required={question.required}
                            />
                        )}

                        {question.question_type === 'rating' && (
                            <div className="rating-scale">
                                {[...Array(question.max_value - question.min_value + 1)].map((_, index) => {
                                    const value = question.min_value + index;
                                    return (
                                        <label key={value}>
                                            <input
                                                type="radio"
                                                name={question.question_id}
                                                value={value}
                                                onChange={() => handleInputChange(question.question_id, value)}
                                                required={question.required}
                                            />
                                            {value}
                                        </label>
                                    );
                                })}
                                <div className="rating-labels">
                                    <span>{question.min_label}</span>
                                    <span>{question.max_label}</span>
                                </div>
                            </div>
                        )}

                        {question.question_type === 'numeric' && (
                            <input
                                type="number"
                                min={question.min_value}
                                max={question.max_value}
                                value={responses[question.question_id] || ''}
                                onChange={(e) => handleInputChange(question.question_id, e.target.value)}
                                required={question.required}
                            />
                        )}

                        {question.question_type === 'date' && (
                            <input
                                type="date"
                                min={new Date().toISOString().split('T')[0]}
                                max={question.max_date}
                                value={responses[question.question_id] || ''}
                                onChange={(e) => handleInputChange(question.question_id, e.target.value)}
                                required={question.required}
                            />
                        )}

                        {/* 上传图片功能 */}
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, question.question_id)}

                            // onChange={(e) => {
                            //     const file = e.target.files[0];
                            //     if (file) {
                            //         const reader = new FileReader();
                            //         reader.onloadend = () => {
                            //             setQuestions(questions.map(q => {
                            //                 if (q.question_id === question.question_id) {
                            //                     return { ...q, image: reader.result }; // 保存图片数据
                            //                 }
                            //                 return q;
                            //             }));
                            //         };
                            //         reader.readAsDataURL(file);
                            //     }
                            // }}
                        />

                        {question.image && (
                            <div className="uploaded-image">
                                {/*<p>Image ID: {question.image}</p>*/}
                                {/*<img src={question.image} alt="Uploaded" style={{ maxWidth: '200px', maxHeight: '200px' }} />*/}
                                <Image src={question.image} alt="Uploaded" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                            </div>
                        )}




                        {/* Add New Question Control */}
                        <div className="add-new-question-controls">
                            <select
                                value={newQuestionType}
                                onChange={(e) => setNewQuestionType(e.target.value)}
                            >
                                <option value="">Select question type</option>
                                <option value="single_choice">Single Choice</option>
                                <option value="multiple_choice">Multiple Choice</option>
                                <option value="open_ended">Open Ended</option>
                                <option value="rating">Rating</option>
                                <option value="numeric">Numeric</option>
                                <option value="date">Date</option>
                            </select>
                            <button
                                type="button"
                                onClick={() => handleAddNewQuestion(index)} // Pass the index to add the question after
                                className="add-question-button"
                            >
                                Add New Question
                            </button>
                            {/* Delete Question */}
                            <button type="button" onClick={() => handleDeleteQuestion(question.question_id)} className="delete-button">Delete Question</button>
                        </div>
                    </div>
                ))}

                {/*<button type="button" onClick={handleSubmit} className="submit-button">*/}
                {/*    Submit Survey*/}
                {/*</button>*/}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button type="button" onClick={() => handleSubmit("draft")} className="submit-button" style={{ width: '45%' }}>
                    Save as Draft
                </button>
                <button type="button" onClick={() => handleSubmit("published")} className="submit-button" style={{ width: '45%' }}>
                    Publish Survey
                </button>
                </div>
            </form>
        </div>
        </div>
    );
};

export default SurveyPage;

